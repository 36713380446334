// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-inquire-examples-js": () => import("./../../../src/pages/inquire/examples.js" /* webpackChunkName: "component---src-pages-inquire-examples-js" */),
  "component---src-pages-inquire-file-upload-js": () => import("./../../../src/pages/inquire/file-upload.js" /* webpackChunkName: "component---src-pages-inquire-file-upload-js" */),
  "component---src-pages-inquire-index-js": () => import("./../../../src/pages/inquire/index.js" /* webpackChunkName: "component---src-pages-inquire-index-js" */),
  "component---src-pages-inquire-ol-dindex-js": () => import("./../../../src/pages/inquire/OLDindex.js" /* webpackChunkName: "component---src-pages-inquire-ol-dindex-js" */),
  "component---src-pages-inquire-thanks-js": () => import("./../../../src/pages/inquire/thanks.js" /* webpackChunkName: "component---src-pages-inquire-thanks-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-features-page-js": () => import("./../../../src/templates/features-page.js" /* webpackChunkName: "component---src-templates-features-page-js" */),
  "component---src-templates-gallery-page-js": () => import("./../../../src/templates/gallery-page.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-location-page-js": () => import("./../../../src/templates/location-page.js" /* webpackChunkName: "component---src-templates-location-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-welcome-page-js": () => import("./../../../src/templates/welcome-page.js" /* webpackChunkName: "component---src-templates-welcome-page-js" */)
}

